@import url("./lib/fonts/fonts.css");
@import url("./lib/icons.less");
@import url("./lib/settings.less");
@import url("./lib/functions.less");
@import url("./lib/animations.less");
@import url("./classes/classes.less");
@import url("./blocks.less");
@import url("./nav.less");





html{
	min-height: 100%;
	width:100%;
	height:100%;
	margin:0;
	padding:0;
	overflow:hidden;
} 

body{
	min-height: 100%;
	width:100%;
	height:100%;
	overflow: hidden;
	display:block;
	
	background-color:@c_6th;
	
	background-repeat: no-repeat;
	background-position: top center;
	background-attachment: fixed;
	background-size: cover;

	position: relative;
	z-index: 0;

	padding: 0;
	margin: 0;

	min-width: 100%;

	font-size: @f_mainSize;
	
	& > canvas{
		width: 80%;
 		height: 80%;
 		position: fixed;
 		z-index: 1;
 		top: 50%;
		left: 50%;
		background-color:#f90000;

		.transform2d(@translateX: -50%; @translateY: -50%);
	}

	#canvasModel{
		width: 80%;
		height: 80%;
		position: fixed;
		z-index: 1;
		top: 50%;
		left: 50%;
		background-color:transparent;

	   .transform2d(@translateX: -50%; @translateY: -50%);
	}
}

*:fullscreen,
*:-ms-fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
	overflow: auto !important;
}

#background-stage{
	
	display:block;
	width:100%;
	height:100%;
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	margin:0;
	padding:0;
	z-index:-2;

	background-image: url('../img/bgStage.jpg');
	background-size: cover;
	background-repeat:no-repeat;
	background-position:center center;

	.standardAnimation(all);

}




