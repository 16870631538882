

#blockFader{
    
    display:block;
    position:absolute;
    top:50%;
    right:300px;
    width:50px;
    height:100%;

    input[type="range"]#fader{
        background-color:#f90000;
        display:block;
        width:550px;
        height:50px;

        .transform2d(@rotate: 90deg);

    }
}

