
.nav-up{
	opacity:0;
}

.nav-down{
	
}

.iPad, .UnknownTablet{
	#main-nav{
		ul.main-nav-elements{

			@media screen and ( max-width: @q_1st ){
				width:580px;
			}

			li{
				
			}
		}
	}
}

#logo-nav{

	display:block;
	width:100%;
	position:absolute;
	bottom:0;
	left:0;
	right:0;
	background-color:@c_6th; // < -- Background Logo Section
	z-index:1;

	.standardAnimation(all);

	&.nav-start{
		height:75%;
    }
    
    &.nav-open{
        opacity:1;
    }

	&.nav-bottom-vertical-tablet{
		height:23%;
	}

	&.nav-bottom-horizontal-tablet{
		height:31%;
	}

	&.nav-open-vertical-tablet{
		height:36%;
	}

	&.nav-open-horizontal-tablet{
		height:60%;
    }

	&.nav-bottom-horizontal-desktop{
		height:21%;

		@media screen and ( max-width: @q_1st ){
			height:33%;
		}
	}

	&.nav-open-horizontal-desktop{
		height:40%;
		
		@media screen and ( max-width: @q_1st ){
			height:65%;
		}
	}
	.nav-up{
		opacity:0;
	}

	#slogan{

		position:absolute;
		top:-350px;
		left:50%;
		width:100%;

		.transform2d(@translateX: -50%);
		.standardAnimation(all);

		h1{
			text-align:center;
			.h1;
			font-size:62px;
			//margin-top:20%!important;
		}
		h2{
			text-align:center;
			.h3;
			text-transform:none;
			color:@c_1st;
			font-size: ( @f_mainSize * 1.375);
		}
	}

	#logo-can{

		display:none;
		width:130px;
		height:130px;
		position: absolute;
		left:50%;
		top:35px;
		z-index:2;

		.transform2d(@translateX: -50%, @translateY: -50%);

		@media screen and ( max-width: @q_3rd ){
			width:100px;
			height:100px;
		}

		.wrapper-logo-can-svg{
			display:block;
			width:100%;
			height:100%;
			top:50%;

			&:before{
				content:" ";
				display:block;
				width:130px;
				height:130px;
				position:absolute;
				border:1px solid @c_5th;
				top:50%;
				left:50%;
				background-color:transparent;
				border-radius:50%;
				z-index:-1;

				.transform2d(@translateX:-50%; @translateY: -50%);

				@media screen and (max-width: @q_3rd ){
					width:100px;
					height:100px;
				}
			}
		}
	}

	#logo-arrow-down{

		display:none;
		width:130px;
		height:130px;
		position:absolute;
		left:50%;
		top:35px;
		z-index:2;

		.transform2d(@translateX: -50%, @translateY: -50%);

		.wrapper-logo-arrow{

			display:block;
			width:100%;
			height:100%;
			top:50%;

			&:before{

				content:" ";
				display:block;
				width:130px;
				height:130px;
				position:absolute;
				border:1px solid @c_5th;
				top:50%;
				left:50%;
				background-color:transparent;
				border-radius:50%;
				z-index:-1;

				.transform2d( @translateX:-50%; @translateY: -50% );

				@media screen and ( max-width: @q_3rd ){
					width:100px;
					height:100px;
				}
			}

			img{
				width:45%;
				height:45%;
				position:absolute;
				top:50%;
				left:50%;

				.transform2d( @translateX:-50%; @translateY: -50% );

				
			}
		}
	}

	#logo{
		display:block;
		width:150px;
		height:150px;
		position: relative;
		left:50%;
		top:35px;
		z-index:2;

		.transform2d(@translateX: -50%, @translateY: -50%);

		@media screen and ( max-width: @q_3rd ){
			width:100px;
			height:100px;
		}

		.wrapper-logo-svg{
			
			display:block;
			width:100%;
			height:100%;
			top:50%;
			
			&:before{

				content:" ";
				display:block;
				width:170px;
				height:170px;
				background-color:@c_6th;
				border-radius:50%;
				position:absolute;
				left:50%;
				top:-10px;
				z-index:-1;
	
				.transform2d(@translateX: -50%);

				@media screen and (max-width: @q_3rd ){
					width:120px;
					height:120px;
				}
			}
			svg{
				&.logo-scale-small{
					.transform2d(@scale: 0 );
				}
				.standardAnimation(all);
			}
		}
	}
	
	#logo-linien{
			
		display:block;
		position:absolute;
		top:0;
		margin-top:( @p_main / 2 );
		width:100%;
		min-height:50px;
		background-color:transparent;
		z-index:1;

		.line{
			display:block;
			width:100%;
			height:3px;
			background-color: @c_4th;
			margin-bottom:4px;
		}
		&.linien-off{
			opacity:0;
		}
	}
}

#main-nav{

	display:none;
	width:100%;
	height:152px;
	overflow:hidden;
	margin:( @p_main ) 0 0 0;
	padding:0;
	position:relative;
	top: 35px;

    ul#main-nav-elements{

        display:inline-block;
        height:auto;
		list-style:none;
		margin:0 0 0 50% ;
		padding:0;
		overflow:hidden;
		position:absolute;
		top:0;
		min-width: 788px;

		.transform2d(@translateX: -50%);

		@media screen and ( max-width: @q_3rd ){
			min-width: 576px;
		}

		li{
            display:block;
            width:150px;
            height:150px;
            border-radius:50%;
            border:1px solid @c_3rd;
			overflow:hidden;
			float:left;
			margin-right: ( @p_main * 3 );

            .main-nav-element-svg{
                display:block;
                width:100%;
                height:100%;
				margin:0 ;

                svg{
                    display:block;
                    width:100%;
                    height:auto;
                    margin:0 auto;

                }
			}
			
			&.about{
				.main-nav-element-svg{
					i{
						display:block;
						width:100%;
						height:100%;
						//background-color:@c_5th;
						//mask-image: url("/assets/img/svg/Logo_font.svg");
						//-webkit-mask-image: url("/assets/img/svg/Logo_font.svg");
					}
				}
			}
			&.factory{
				.main-nav-element-svg{
					i{
						display:block;
						width:120px;
						height:77px;
						margin:35px auto 0 auto;
						//background-color:@c_5th;
						//mask-image: url("/assets/img/svg/GAT_Factory.svg");
						//-webkit-mask-image: url("/assets/img/svg/GAT_Factory.svg");
					}
				}
				@media screen and ( max-width: @q_3rd ){
					margin-right:0;
				}
			}
			&.exit{
				.main-nav-element-svg{
					img{
						width:75px;
						height:75px;
						margin-left:25%;
						margin-top:25%;
					}
				}

				@media screen and ( max-width: @q_3rd ){
					display:none;
				}

				.standardAnimation(all);
			}
			
			&:last-child{
				margin-right:0;
			}
		}
		clear:both;
	}

	#nav-systems-parent{

		display:block;
		height:100%;
		width:100%;
		overflow:hidden;
		overflow-x:auto;
		position:absolute;
		top:152px;
		left:0;

		.no-scrollbar;

		ul#nav-systems{

			display:block;
			list-style:none;
			position:absolute;
			margin:0;
			padding:0 ( @p_main * 2 );
			width:100%;

	
			li{
	
				float:left;
				padding-right: ( @p_main * 4 );
				max-width:120px;
	
				.systems-wrapper{
	
					position:relative;
	
					.wrapper-headline{
						
						position:absolute;
						right:-(@p_main / 2);
						height:152px;
						bottom:0;
						width:100%;
	
						h6{
							font-size: ( @f_mainSize * 1.125 );
							margin:0;
							padding:0;
							display:block;
							text-align:right;
							line-height:100%;
							//width:100%;
							height:0;
							width:152px;
	
							//height:auto;
					
							.transform2d(@rotate: -90deg, @translateY:  -50%, @translateX: -50% );
							.f_1stFontBold;
							
							&.petrol{
								color:@c_petrolRed;
							}
							&.lpg{
								color:@c_lpgOrange;
							}
							&.diesel{
								color:@c_dieselYellow;
							}
							&.nano{
								color:@c_nanoGreen;
							}
							&.oil{
								color:@c_oilGreen;
							}
							&.carcare{
								color:@c_carCareMint;
							}
							&.aircon{
								color:@c_airconBlue;
							}
							&.cooler{
								color:@c_coolerBlue;
							}
							&.gear{
								color: @c_automatikGrey;;
							}
						}
					}
					.imgBox-Car{
						
						width:100%;
	
						img{
							width:auto;
							height:152px;
						}
					}
				}
			}
			clear:both;
		}
	}
}

#nav-about-parent{
	//display:none;

	ul#nav-about{
	}
}

#nav-product-parent{

	display:block;
	height:100%;
	width:100%;
	overflow:hidden;
	overflow-x:auto;
	position:absolute;
	top:152px;
	left:0;

	.no-scrollbar;

	ul#nav-products{

		list-style:none;
		margin:0;
		padding:0;
		width:auto;
		height:100%;
		display:block;
		position: absolute;
		left:50%;
		//display:none;

		.transform2d(@translateX: -50%);

		li{

			//display:inline-block;
			width:auto;
			min-width:180px;
			height:100%;
			margin:0;
			padding:0;
			float:left;

			.nav-wrapper{

				display:block;
				//width:100%;
				height:100%;
				position:relative;

				.anchor-products{

					display:block;
					//width:100%;
					height:100%;

					.img-wrapper{

						display:block;
						//width:100%;
						height:100%;
						//position:relative;

						img{
							//position:absolute;
							//top:47%;
							//left:50%;
							height:100%;
							display:block;
							width:auto;
							margin:0;

							//.transform2d(@translateX: -50%, @translateY: -50% );
						}
					}
					.wrapper-headline{

						position:absolute;
						right:-30px;
						height:150px;
						bottom:0;
						width:100%;

						h6{
							font-size: @f_mainSize;
							text-align:right;
							line-height:100%;
							height:0;
							width:152px;
							display:block;
							margin:0;
							padding:0;

							.transform2d(@rotate: -90deg, @translateX: -50%, @translateY: -50%);
							.f_1stFontBold;

							&.oilsystem{
								color:@c_oilGreen;
							}
							&.fuelsystem{
								color:@c_petrolRed;
				
							}
							&.lpgsystem{
								color:@c_lpgOrange;
							}
							&.dieselsystem{
								color:@c_dieselYellow;
				
							}
							&.nano-surface{
								color:@c_nanoGreen;
							}
							&.carcare{
								color:@c_carCareMint;
							}
							&.airconsystem{
								color:@c_airconBlue;
							}
							&.coolersystem{
								color:@c_coolerBlue;
							}
							&.gearbox{
								color: @c_automatikGrey;
							}
							&.service{
								color:@c_8th;
							}
						}
					}
				}
			}
		}
		clear:both;
	}
}

